import { globalTheme } from "srs.sharedcomponents/lib/esm/styles/config";

const mitsubishiThemeColor = "#e4002b";

export const customTheme = {
  ...globalTheme,
  colors: {
    ...globalTheme.colors,
    primary: mitsubishiThemeColor,
  },
};
